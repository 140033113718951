import React, { useEffect, useState } from 'react'
import sharingsResource from '../resources/sharings'

function SessionProvider (props) {
  const [requestState, setRequestState] = useState({
    loading: false,
    error: null
  })
  const [session, setSession] = useState({
    did: null,
    token: null,
    device: null
  })

  useEffect(() => {
    async function retrieveData () {
      try {
        const hash = getSessionId()
        if (!hash) throw new Error('hash_id_not_provided')
        setRequestState({ loading: true, error: null })
        const { data: sessionData } = await sharingsResource().getSharing(hash)
        const { data: deviceData } = await sharingsResource().getDevice(sessionData.token)
        setRequestState({ loading: false, error: null })
        setSession({ ...sessionData, device: deviceData })
      } catch (error) {
        console.error(error)
        setRequestState({ loading: false, error })
      }
    }
    retrieveData()
  }, [])

  function getSessionId () {
    const params = window.location.pathname.split('/')
    const sessionId = params[1] ? params[1] : null
    return sessionId
  }

  return (
    <>
      {props.children(session, requestState.loading, requestState.error)}
    </>
  )
}

export default SessionProvider
