import './index.css'
import React, { useEffect, useState } from 'react'
import { Marker } from 'react-map-gl'
import BackgroundPositionAnimationX from './background-position-animation'

const marker = React.createRef()

function CustomMarker (props) {
  const deltas = 49
  const [deltaCount, setDeltaCount] = useState(0)
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null
  })

  useEffect(() => {
    let frameID = 0
    if (props.feature !== null) {
      setDeltaCount(0)
      if (currentLocation.latitude === null && currentLocation.longitude === null) {
        window.requestAnimationFrame(() => {
          frameID = setCurrentLocation({ latitude: props.feature.geometry.coordinates[1], longitude: props.feature.geometry.coordinates[0] })
        })
      }
    }
    window.cancelAnimationFrame(frameID)
  }, [currentLocation.latitude, currentLocation.longitude, props.feature])

  useEffect(() => {
    let frameID = 0
    if (deltaCount <= deltas) {
      if (currentLocation.latitude !== null && currentLocation.longitude !== null) {
        const latitudeDelta = calculateDelta(currentLocation.latitude, props.feature.geometry.coordinates[1], deltas)
        const longitudeDelta = calculateDelta(currentLocation.longitude, props.feature.geometry.coordinates[0], deltas)
        const nextLngLat = deltaCount === deltas ? props.feature.geometry.coordinates : [currentLocation.longitude + longitudeDelta, currentLocation.latitude + latitudeDelta]
        frameID = window.requestAnimationFrame(() => {
          setCurrentLocation({ latitude: nextLngLat[1], longitude: nextLngLat[0] })
        })
      }
      frameID = window.requestAnimationFrame(() => {
        setDeltaCount(deltaCount + 1)
      })
    }
    window.cancelAnimationFrame(frameID)
  }, [currentLocation, deltaCount, props.feature])

  function calculateDelta (previous, next, deltas) {
    return (next - previous) / deltas
  }

  if (props.feature === null || currentLocation.latitude === null || currentLocation.longitude === null) return null
  return (
    <Marker
      ref={marker}
      key='marker-background-position'
      latitude={currentLocation.latitude}
      longitude={currentLocation.longitude}
      anchor='center'
      offsetTop={-75}
      offsetLeft={-75}
    >
      <BackgroundPositionAnimationX direction={props.feature.properties.dir}>
        {
          (backgroundPositionX) => (
            <div className='CustomMarker' style={{ backgroundPositionX }} />
          )
        }
      </BackgroundPositionAnimationX>
    </Marker>
  )
}

export default CustomMarker
