import React from 'react'
import { Source, Layer } from 'react-map-gl'

const ERB_LOCATIONS_SOURCE = 'mapbox://marcelosoftruck.1qwogdot'
const ERB_LOCATIONS = {
  id: 'erb-stations',
  type: 'circle',
  source: 'erbs-source',
  'source-layer': 'erbs-6gkze7',
  paint: {
    'circle-color': [
      'match',
      ['get', 'owner'],
      'OI MÓVEL S.A.', '#FBB03B',
      'TELEFÔNICA BRASIL S.A.', '#650099',
      'CLARO S.A.', '#E55E5E',
      'TIM S/A', '#3BB2D0',
      '#223B53'
    ],
    'circle-radius': {
      base: 1.7,
      stops: [
        [12, 2],
        [22, 180]
      ]
    }
  },
  minzoom: 14
}

function ERBLocations (props) {
  return (
    <Source
      id='erbs-source'
      type='vector'
      url={ERB_LOCATIONS_SOURCE}
    >
      <Layer
        {...ERB_LOCATIONS}
      />
    </Source>
  )
}

export default ERBLocations
