import React, { useState } from 'react'
import RiskAreas from '../mapbox-features/risk-areas'
import ERBLocations from '../mapbox-features/erbs'
import LiveMap from '../components/live-map'
import CustomMarker from '../components/custom-marker'
import InfoPanel from '../components/info-panel'
import StyleToggle from '../components/style-toggle'

function LiveMapView (props) {
  const [style, setStyle] = useState('streets')
  return (
    <>
      <LiveMap
        mapboxApiAccessToken={props.mapboxApiAccessToken}
        feature={props.featureState.data}
        style={style}
      >
        <RiskAreas />
        <ERBLocations />
        <CustomMarker feature={props.featureState.data} />
        <InfoPanel feature={props.featureState.data} address={props.address} device={props.device} />
        <StyleToggle onChangeStyle={(style) => setStyle(style)} />
      </LiveMap>
    </>
  )
}

export default LiveMapView
