import axios from 'axios'

const sharingsResource = () => {
  const sharingsEndpoint = `${process.env.REACT_APP_API_URL}/api/v2/sharings`
  return {
    getSharing: (hash) => axios.get(`${sharingsEndpoint}/public/${hash}`),
    getDevice: (token) => axios.get(`${sharingsEndpoint}/device`, { headers: { Authorization: `Bearer ${token}` } })
  }
}

export default sharingsResource
