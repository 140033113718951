import './index.css'
import React from 'react'

function LoadingIndicator (props) {
  return (
    <div className='LoadingIndicator'>
      <div />
      <div />
    </div>
  )
}

export default LoadingIndicator
