import axios from 'axios'

const geocoderResource = () => {
  const geocoderEndpoint = process.env.REACT_APP_API_URL
  return {
    reverseResolve: (longitude, latitude, token) => axios.get(`${geocoderEndpoint}/api/v3/geocoder/reverse_resolve/${longitude}/${latitude}`, { headers: { Authorization: `Bearer ${token}` } })
  }
}

export default geocoderResource
