import axios from 'axios'

const trackingResource = () => {
  const trackingEndpoint = process.env.REACT_APP_TRACKING_API_URL
  return {
    singleDevice: (deviceId, token) => axios.get(`${trackingEndpoint}/devices/${deviceId}?token=${token}`)
  }
}

export default trackingResource
