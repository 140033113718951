import './index.css'
import React, { useState } from 'react'
import SoftruckLogo from '../softruck-logo'

const WARNING = {
  unauthorized: {
    title: 'Compartilhamento Expirado.',
    description: 'O tempo de vida desse compartilhamento já expirou. Por favor, solicite um novo link ou insira manualmente seu código abaixo.'
  },
  not_found: {
    title: 'Compartilhamento Inválido.',
    description: 'Esse código de compartilhamento não é válido. Por favor, solicite um novo link ou insira manualmente seu código abaixo.'
  },
  default_error: {
    title: 'Ops! Esbarramos em um problema inesperado.',
    description: 'Por favor, verificar a URL compartilhada. Caso esse aviso persista, peça o link do compartilhamento novamente ou insira manualmente seu código abaixo.'
  },
  default: {
    title: 'Bem vindo ao módulo de compartilhamento.',
    description: 'Por favor, insira abaixo seu código para continuar.'
  }
}

const Message = props => {
  const [sharingHash, setHash] = useState('')

  const redirectHash = () => {
    const url = window.location.href.slice(0, window.location.href.indexOf('/', 8))
    window.location.href = `${url}/${sharingHash}`
  }

  return (
    <div className='WarningMessageOverlay'>
      <div className='Content'>
        <SoftruckLogo mainSize />
        <h2 className='Title'>{props.title}</h2>
        <p className='Description'>{props.description}</p>
        <div className='Redirector'>
          <input placeholder='JKV17A47EQUWGGGEJ8EG' onChange={(e) => setHash(e.target.value)} />
          <button onClick={redirectHash}><span className='material-icons'>arrow_forward_ios</span></button>
        </div>
      </div>
    </div>
  )
}

function WarningMessage (props) {
  let content = WARNING.default

  if (props.error) {
    if (props.error.response) {
      switch (props.error.response.status) {
        case 401:
          // window.gtag('401', 'access', {
          //   event_category: 'expired',
          //   event_label: 'unauthorized'
          // })
          content = WARNING.unauthorized
          break
        case 404:
          // window.gtag('404', 'access', {
          //   event_category: 'not_found',
          //   event_label: 'not_found'
          // })
          content = WARNING.not_found
          break
        default:
      }
    } else if (props.error.message) {
      switch (props.error.message) {
        case 'hash_id_not_provided':
          content = WARNING.default
          break
        default:
      }
    }
  }

  return (
    <Message
      title={content.title}
      description={content.description}
    />
  )
}

export default WarningMessage
