import './index.css'
import React from 'react'
import { setClass } from '../../libs/utils'

const SoftruckLogo = ({ mainSize, white }) => {
  const Classes = {
    LogoImage: setClass({ LogoImage: true, MainImage: mainSize, White: white }),
    LogoText: setClass({ LogoText: true, MainText: mainSize, White: white })
  }

  return (
    <div className='SoftruckLogo'>
      <span className={Classes.LogoImage} />
      <span className={Classes.LogoText} />
    </div>
  )
}

export default SoftruckLogo
