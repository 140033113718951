import React, { useState, useEffect } from 'react'
import MapGL, { FlyToInterpolator, NavigationControl } from 'react-map-gl'

const styles = {
  streets: 'mapbox://styles/mapbox/streets-v11?optimize=true',
  satellite: 'mapbox://styles/mapbox/satellite-v9?optimize=true'
}
function LiveMap (props) {
  const [viewState, setViewState] = useState({
    zoom: !props.feature ? 13 : 16,
    latitude: !props.feature ? 0 : props.feature.geometry.coordinates[1],
    longitude: !props.feature ? 0 : props.feature.geometry.coordinates[0]
  })
  const [recenter, setRecenter] = useState({ allow: true, cooldownCallback: null })

  useEffect(() => {
    const coordinates = props.feature ? props.feature.geometry.coordinates : null
    if (coordinates !== null && recenter.allow) {
      setViewState({
        zoom: 16,
        latitude: coordinates[1],
        longitude: coordinates[0],
        transitionDuration: 750,
        transitionInterpolation: new FlyToInterpolator({ speed: 1.2 })
      })
    }
  }, [props.feature])

  function onViewStateChange ({ viewState, interactionState, oldViewState }) {
    setViewState(viewState)

    if (interactionState && interactionState.isPanning) {
      clearTimeout(recenter.cooldownCallback)
      setRecenter({ allow: false, cooldownCallback: setTimeout(() => setRecenter({ allow: true, cooldownCallback: null }), 20 * 1000) })
    }
  }

  return (
    <div style={{
      minHeight: '100px'
    }}
    >
      <MapGL
        mapboxApiAccessToken={props.mapboxApiAccessToken}
        onViewStateChange={onViewStateChange}
        mapStyle={styles[props.style || 'streets']}
        viewState={viewState}
        width={`${window.innerWidth}px`}
        height={`${window.innerHeight}px`}
      >
        <div className='NavigationControl'>
          <NavigationControl showCompass={false} showZoom />
        </div>
        {props.children}
      </MapGL>
    </div>
  )
}

export default LiveMap
