import React, { useEffect, useState } from 'react'
import trackingResource from '../resources/tracking'
import geocoderResource from '../resources/geocoder'

function TrackingProvider (props) {
  const [requestState, setRequestState] = useState({
    loading: false,
    error: null
  })
  const [feature, setFeature] = useState(null)
  const [address, setAddress] = useState(null)

  useEffect(() => {
    let unauthorized = false
    async function retrieveData () {
      try {
        setRequestState({ loading: true, error: null })

        const { data: featureData } = await trackingResource().singleDevice(props.deviceId, props.token)

        const [long, lat] = featureData.geometry.coordinates
        const { data: addressData } = await await geocoderResource().reverseResolve(long, lat, props.token)

        setRequestState({ loading: false, error: null })

        setFeature(featureData)
        setAddress(addressData)
      } catch (error) {
        setRequestState({ loading: false, error })
        unauthorized = error.response && error.response.status === 401
      }
      if (!unauthorized) setTimeout(() => retrieveData(), 5000)
    }
    retrieveData()
  }, [props.deviceId, props.token])

  return (
    <>
      {props.children(feature, address, requestState.loading, requestState.error)}
    </>
  )
}

export default TrackingProvider
