import './index.css'
import React, { useState } from 'react'

const options = {
  streets: 'satellite',
  satellite: 'streets'
}

function StyleToggle ({ onChangeStyle }) {
  const [style, setStyle] = useState(options.satellite)

  const toogleStyle = () => {
    const newStyle = options[style]
    setStyle(newStyle)
    if (onChangeStyle) {
      onChangeStyle(newStyle)
    }
  }

  return (
    <div
      className='map-preview'
      style={{ backgroundImage: `url('/assets/maps/${options[style]}.png')` }}
      onClick={toogleStyle}
    />
  )
}

export default StyleToggle
