import React, { useEffect } from 'react'
import LiveMapView from '../views/live-map-view'
import TrackingProvider from '../providers/tracking-provider'
import WarningMessage from '../components/warning-message'
import LoadingOverlay from '../components/loading-overlay'

function LiveMapContainer (props) {
  useEffect(() => {
    function updateCustomViewportUnit () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      const vw = window.innerWidth * 0.01
      document.documentElement.style.setProperty('--vw', `${vw}px`)
    }

    updateCustomViewportUnit()
    window.addEventListener('resize', updateCustomViewportUnit)
  }, [])

  return (
    <TrackingProvider deviceId={props.session.did} token={props.session.token}>
      {
        (data, address, isLoading, error) => (
          <>
            {
              error
                ? (<WarningMessage error={error} />)
                : (isLoading && data === null)
                  ? <LoadingOverlay />
                  : <LiveMapView mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} featureState={{ data, isLoading, error }} address={address} device={props.session ? props.session.device : null} />
            }
          </>
        )
      }
    </TrackingProvider>
  )
}

export default LiveMapContainer
