import './index.css'
import React from 'react'
import LoadingIndicator from '../loading-indicator'

function LoadingOverlay (props) {
  return (
    <div className='Overlay'>
      <LoadingIndicator />
    </div>
  )
}

export default LoadingOverlay
