
const isEmptyObject = objectToValidate => {
  if (objectToValidate.constructor !== Object) throw new TypeError('isEmptyObject expects an javascript object as parameter.')
  return Object.keys(objectToValidate).length === 0
}

const getCardinalDirection = (direction, isShortened = false) => {
  if (Number.isNaN(direction) || (!direction && direction !== 0)) { return '-' }

  const degrees = direction % 360

  if (degrees >= 22.5 && degrees < 67.5) {
    return isShortened ? 'NE' : 'Nordeste'
  } else if (degrees >= 67.5 && degrees < 112.5) {
    return isShortened ? 'L' : 'Leste'
  } else if (degrees >= 112.5 && degrees < 157.5) {
    return isShortened ? 'SE' : 'Sudeste'
  } else if (degrees >= 157.5 && degrees < 202.5) {
    return isShortened ? 'S' : 'Sul'
  } else if (degrees >= 202.5 && degrees < 247.5) {
    return isShortened ? 'SO' : 'Sudoeste'
  } else if (degrees >= 247.5 && degrees < 292.5) {
    return isShortened ? 'O' : 'Oeste'
  } else if (degrees >= 292.5 && degrees < 337.5) {
    return isShortened ? 'NO' : 'Noroeste'
  } else {
    return isShortened ? 'N' : 'Norte'
  }
}

const copyToClipboard = (coordenates) => {
  navigator.clipboard.writeText(coordenates)
}

function timestampToDateString (timestamp, locale = 'pt-br') {
  if (Number.isInteger(timestamp)) {
    const time = new Date(timestamp * 1000)
    return time.toLocaleString(locale)
  }
}

function setClass (obj = {}) {
  let className = ''
  for (const key in obj) {
    if (obj[key]) className += `${key} `
  }
  return className
}

export {
  isEmptyObject,
  getCardinalDirection,
  timestampToDateString,
  setClass,
  copyToClipboard
}
