import './index.css'
import React, { useState } from 'react'
import { getCardinalDirection, timestampToDateString, copyToClipboard } from '../../libs/utils'
import SoftruckLogo from '../softruck-logo'

function InfoPanel (props) {
  const [toggled, setToggledState] = useState(true)

  const [classes, setClassState] = useState({
    Arrow: 'Unreverse',
    ControlPanel: 'ControlPanel'
  })

  const toggleCard = () => {
    const currentToggled = !toggled
    setToggledState(currentToggled)
    setClassState({
      Arrow: currentToggled ? 'Unreverse' : 'Reverse',
      ControlPanel: currentToggled ? 'ControlPanel' : 'ControlPanel HiddenContent'
    })
  }

  function getColorHex (color) {
    switch (color) {
      case 'grn':
        return 'limegreen'
      case 'red':
        return 'tomato'
      case 'ylw':
        return 'gold'
      default:
        return 'lightgrey'
    }
  }

  if (props.feature === null) return null
  return (
    <div className='PanelWrapper'>
      <div className='CollapseButton' onClick={toggleCard}>
        <div className={classes.Arrow}><div className='material-icons'>expand_more</div></div>
        <span className='Text'>Info.</span>
      </div>
      <div className={classes.ControlPanel}>
        <div className='Header'>
          <div className='StatusIndicator' style={{ backgroundColor: getColorHex(props.feature.properties.st) }} />
          <div className='Column HorizontalPad'>
            <div className='Row'>
              <label className='Title' htmlFor='accordion'>Rastreando o veículo <strong>{props.feature.properties.lbl}</strong></label>
            </div>
            <div className='Row'>
              <label className='Model' htmlFor='accordion'>{props.device && props.device.vehicle ? props.device.vehicle.model : 'Modelo desconhecido'}</label>
            </div>
          </div>
        </div>

        <div className='Content'>
          <div className='LastStatuses HorizontalPad'>
            <div className='Column FlexStart VerticalInterspace'>
              <div className='Row'>
                <div className='DisplayTime'>
                  <p className='Label'>Última atualização:</p>
                  <p className='Value'>{timestampToDateString(props.feature.properties.act)}</p>
                </div>
              </div>

              <div className='Row'>
                <p className='Label'>Último endereço:</p>
              </div>
              <div className='Row'>
                <div className='Column FlexEnd'>
                  {
                    props.address
                      ? (
                        <>
                          <p className='Value NoPad'>{`${props.address.road}, ${props.address.postcode}`}</p>
                          <p className='Value NoPad'>{`${props.address.city} - ${props.address.state}`}</p>
                        </>
                      )
                      : null
                  }
                </div>
              </div>

              <div className='Row End'>
                <p className='Value NoPad'><strong className='ColorCoordenates'>{props.feature.geometry.coordinates[1].toFixed(6)}</strong>,</p>
                <p className='Value NoPad'><strong className='ColorCoordenates'>{props.feature.geometry.coordinates[0].toFixed(6)}</strong></p>
                <button className='ButtonCopy' type='button' onClick={() => copyToClipboard([props.feature.geometry.coordinates[1], props.feature.geometry.coordinates[0]])} />
              </div>

            </div>
          </div>

          <div className='GPSContent HorizontalPad'>
            <div className='Label NoPad'>Informações sobre o veículo</div>
            <div className='GPSAttributes Row SpaceAround'>
              <div className='Column AlignCenter'>
                <span className='Icon Ignition' />
                <p className='Label NoPad'>Ignição</p>
                <p className='Value NoPad'><strong>{props.feature.properties.ign ? 'Ligada' : 'Desligada'}</strong></p>
              </div>
              <div className='Column AlignCenter'>
                <span className='Icon Direction' />
                <p className='Label NoPad'>Direção</p>
                <p className='Value NoPad'><strong>{getCardinalDirection(props.feature.properties.dir)}</strong></p>
              </div>
              <div className='Column AlignCenter'>
                <span className='Icon Voltage' />
                <p className='Label NoPad'>Voltagem</p>
                <p className='Value NoPad'><strong>{props.feature.properties.pv}V</strong></p>
              </div>
              <div className='Column AlignCenter'>
                <span className='Icon Device' />
                <p className='Label NoPad'>Nível de bateria</p>
                <p className='Value NoPad'><strong>{props.feature.properties.bl} %</strong></p>
              </div>
            </div>
          </div>
          <div className='Caption Column VerticalPad HorizontalPad'>
            <p className='CaptionTitle'>Legenda</p>
            <div className='Row'>
              <p className='CaptionTitle'>Operadoras</p>
            </div>
            <div className='Row SpaceBetween'>
              <p className='CaptionSubTitle'><span className='CaptionColorIndicator Oi' />  Oi</p>
              <p className='CaptionSubTitle'><span className='CaptionColorIndicator Vivo' />  Vivo</p>
              <p className='CaptionSubTitle'><span className='CaptionColorIndicator Claro' /> Claro</p>
              <p className='CaptionSubTitle'><span className='CaptionColorIndicator Tim' /> TIM</p>
              <p className='CaptionSubTitle'><span className='CaptionColorIndicator Others' />  Outros</p>
            </div>
            <div className='Row'>
              <p className='CaptionTitle'>Áreas de Risco</p>
            </div>
            <div className='Row SpaceBetween'>
              <span className='CaptionColorIndicator RiskAreas' />
              <SoftruckLogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoPanel
