import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import SessionProvider from './providers/session-provider'
import LiveMapContainer from './containers/live-map-container'
import LoadingOverlay from './components/loading-overlay'
import WarningMessage from './components/warning-message'

function App () {
  return (
    <SessionProvider>
      {
        (session, isLoading, error) => (session.did && session.token)
          ? <LiveMapContainer session={session} />
          : (isLoading && error === null)
            ? <LoadingOverlay />
            : <WarningMessage error={error} />
      }
    </SessionProvider>
  )
}

export default App
