import React, { useEffect, useState } from 'react'

function BackgroundPositionAnimationX (props) {
  const [direction, setDirection] = useState(0)
  const [backgroundPositionX, setBackgroundPositionX] = useState(0)

  useEffect(() => {
    let frameID = 0
    const backgroundMovementLimit = props.direction * 0.1111111111111111 * 456
    if (Math.abs(backgroundMovementLimit - backgroundPositionX) > 152) {
      if (backgroundPositionX < backgroundMovementLimit) {
        frameID = window.requestAnimationFrame(() => setBackgroundPositionX(backgroundPositionX + 152))
      }
      if (backgroundPositionX > backgroundMovementLimit) {
        frameID = window.requestAnimationFrame(() => setBackgroundPositionX(backgroundPositionX - 152))
      }
      if (direction !== props.direction) {
        window.cancelAnimationFrame(frameID)
        setDirection(props.direction)
      }
    }
  }, [backgroundPositionX, direction, props.direction])

  return (
    <>
      {props.children(backgroundPositionX)}
    </>
  )
}

export default BackgroundPositionAnimationX
