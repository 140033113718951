import React from 'react'
import { Source, Layer } from 'react-map-gl'

const RISK_AREA_SOURCE = 'mapbox://marcelosoftruck.3rofo7xz'
const RISK_AREA_LAYER = {
  id: 'risk-areas',
  type: 'fill',
  source: 'risk-areas-source',
  'source-layer': 'risk-areas-5yiu13',
  paint: {
    'fill-color': '#FF5722',
    'fill-opacity': 0.3
  },
  minzoom: 12
}
const RISK_AREA_BORDER_LAYER = {
  id: 'risk-areas-border',
  type: 'line',
  source: 'risk-areas-source',
  'source-layer': 'risk-areas-5yiu13',
  paint: {
    'line-opacity': 0.6,
    'line-color': '#FF5722',
    'line-width': {
      stops: [
        [11, 1],
        [12, 2]
      ]
    }
  },
  minzoom: 12
}
const RISK_AREA_LABEL_LAYER = {
  id: 'risk-areas-label',
  type: 'symbol',
  source: 'risk-areas-source',
  'source-layer': 'risk-areas-5yiu13',
  paint: {
    'text-color': '#FFFFFF',
    'text-halo-color': '#212121',
    'text-halo-width': 1,
    'text-opacity': 1
  },
  layout: {
    'icon-allow-overlap': false,
    'text-field': '{name}',
    'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Regular'],
    'text-size': ['interpolate', ['linear'], ['zoom'],
      16, 16,
      25, 25
    ],
    'text-transform': 'none',
    'text-justify': 'center',
    'text-letter-spacing': 0.05
  },
  minzoom: 14
}

function RiskAreas (props) {
  return (
    <Source
      id='risk-areas-source'
      type='vector'
      url={RISK_AREA_SOURCE}
    >
      <Layer {...RISK_AREA_LAYER} />
      <Layer {...RISK_AREA_BORDER_LAYER} />
      <Layer {...RISK_AREA_LABEL_LAYER} />
    </Source>
  )
}

export default RiskAreas
